<template>
    <v-container fluid>
        <v-row justify="center">
            <div>
                <v-radio-group v-model="type" row>
                    <v-radio label="POS" value="POS"></v-radio>
                    <v-radio label="A4" value="A4"></v-radio>
                    <!-- <v-radio label="PAD" value="PAD"></v-radio> -->
                </v-radio-group>
            </div>
            <v-col cols="12">
                <sale-invoice v-if="type=='A4'" :saleId="this.$route.params.id"></sale-invoice>
                <sale-invoice-pos v-if="type=='POS'" :saleId="this.$route.params.id"></sale-invoice-pos>
                <sale-invoice-pad v-if="type=='PAD'" :saleId="this.$route.params.id"></sale-invoice-pad>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import saleInvoice from "../../components/sale-invoice.vue";
import saleInvoicePos from "../../components/sale-invoice-pos.vue";
import saleInvoicePad from "../../components/sale-invoice-pad.vue";

export default {
    name: 'SaleInvoices',
    data: () => ({
        type: 'POS',
        // company: {
        //     company_name: '',
        //     address: '',
        //     contact_us: '',
        //     email: '',
        //     type: ''
        // },
    }),

    // async created() {
    //     await this.$store.dispatch('companyProfile/getCompanyProfile');
    //     this.company.company_name = this.$store.getters['companyProfile/company'].company_name;
    //     this.company.address = this.$store.getters['companyProfile/company'].address;
    //     this.company.contact_us = this.$store.getters['companyProfile/company'].contact_us;
    //     this.company.email = this.$store.getters['companyProfile/company'].email;
    //     this.company.type = this.$store.getters['companyProfile/company'].type;
    // },

    components: {
		"sale-invoice": saleInvoice,
		"sale-invoice-pos": saleInvoicePos,
		"sale-invoice-pad": saleInvoicePad,
    },
}
</script>
